import { ParcelData } from '~/models'

const PARCEL_DATA_KEY_LABELS: { [key: string]: string } = {
  parcel_id: 'Parcel ID',
  updated: 'Updated',
  address: 'Address',
  city: 'City',
  county: 'County',
  state_abbr: 'State Abbr',
  zip_code: 'Zip Code',
  // 'county_fip': '',
  zoning: 'Zoning',
  zoning_description: 'Zoning Desc',
  legal_description: 'Legal Desc',
  owner: 'Owner',
  owner_address: 'Owner Addr',
  owner_address_2: 'Owner Addr 2',
  sale_price: 'Sale Price',
  transaction_date: 'Transaction Date',
  year_built: 'Year Built',
  building_sf: 'Building SF',
  acres: 'Acres',
  acres_calculated: 'Acres Calc',
  number_of_buildings: 'Num of Buildings',
  floors: 'Floors',
  land_use_code: 'Land Use Code',
  land_use_description: 'Land Use Desc',
  flood_zone: 'Flood Zone',
  flood_zone_subtype: 'Flood Zone Subtype',
  elevation: 'Elevation',
  market_value_building: 'Market Value Building',
  market_value_land: 'Market Value Land',
  market_value_total: 'Market Value Total',
  latitude: 'Latitude',
  longitude: 'Longitude',
}

export function getParcelLabelValues(parcelData: ParcelData) {
  return Object.keys(PARCEL_DATA_KEY_LABELS)
    .filter((key) => parcelData[key as keyof ParcelData])
    .map((key) => {
      return {
        key,
        label: PARCEL_DATA_KEY_LABELS[key],
        value: parcelData[key as keyof ParcelData],
      }
    })
}
